<template>
  <div class="pr-2">
    <DynamicScroller
      :items="list"
      :min-item-size="54"
      class="scroller videos"
      key-field="videoId"
      @scroll.native="checkLastItem"
    >
      <template v-slot="{ item, index, active }">
        <DynamicScrollerItem
          :item="item"
          :active="active"
          :data-index="index"
        >
        <div class="flex items-center w-full py-4 border-b border-dashed border-purple-100">
          <div class="w-28 h-16 rounded -ml-2 mr-3 relative">

            <div class="w-full h-full rounded bg-cover" :style="`background-size: 115%; background-position: 50%; background-image: url('https://i3.ytimg.com/vi/${item.videoId}/default.jpg')`"></div>

            <div 
              class="absolute top-0 left-0 h-full w-8"
              :style="{
                background: 'linear-gradient(90deg, #DCD3FF 20%, rgba(220, 211, 255, 0) 95.91%)'
              }"
            ></div>
          </div>
          <div :style="{ width: 'calc(100% - 4rem)' }">
            <p class="font-sansDemi text-sm text-black">{{ item.title}}</p>
            <p class="font-sans text-xs text-black">{{ item.channelName }}</p>
          </div>
        </div>
        </DynamicScrollerItem>
      </template>
    </DynamicScroller>
  </div>
</template>

<script>
  export default {
    props: [ 'list' ],
    methods: {
      checkLastItem(el){
        if ( el.path[0].offsetHeight + el.path[0].scrollTop >= el.path[0].scrollHeight ) {
          this.$emit('lastItem')
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
.videos{
  height: calc(100% - 1px);
}
</style>